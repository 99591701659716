import React from 'react';
import { useTranslation } from 'react-i18next';
import BG from '../../img/bg.webp';

function Hero() {
  const { t, i18n } = useTranslation();
  return (
    <section className=" overflow-hidden">
      <div
        className="relative overflow-hidden bg-cover bg-no-repeat bg-[50%] h-[500px]"
        style={{ backgroundImage: `url(${BG})` }}>
        <div className="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-fixed bg-[hsla(0,0%,0%,0.75)]">
          <div className="flex h-full items-center justify-center">
            <div className="px-6 text-center text-white md:px-12">
              <div className="mb-2 grid grid-col">
                <span className="mt-10 mb-5 text-4xl font-bold tracking-tight md:text-4xl xl:text-4xl">
                  {t('brand')}
                </span>

                <span className="mb-5">نقطه عطف تغییر سبک زندگی</span>
              </div>
              <div className="grid grid-col items-center gap-3 justify-center md:flex-row md:justify-center">
                <a
                  dir="rtl"
                  className="mb-2 bg-lighter inline-block rounded-full border-2 border-neutral-50 px-[46px] pt-[14px] pb-[12px] text-sm font-medium uppercase leading-normal text-neutral-50 transition duration-150 ease-in-out hover:border-neutral-100 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-neutral-100 focus:border-neutral-100 focus:text-neutral-100 focus:outline-none focus:ring-0 active:border-neutral-200 active:text-neutral-200 md:mr-2 md:mb-0"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                  href="/private"
                  role="button">
                  ثبت نام دوره خصوصی
                </a>
                <a
                  dir="rtl"
                  className="mb-2 inline-block bg-gold text-main rounded-full border-2 border-neutral-50 px-[46px] pt-[14px] pb-[12px] text-sm font-medium uppercase leading-normal  transition duration-150 ease-in-out hover:border-neutral-100 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-neutral-100 focus:border-neutral-100 focus:text-neutral-100 focus:outline-none focus:ring-0 active:border-neutral-200 active:text-neutral-200 md:mr-2 md:mb-0"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                  href="/shapeup"
                  role="button">
                  ثبت نام دوره نیمه خصوصی
                </a>
                <a
                  dir="rtl"
                  className="mb-2 bg-lighter inline-block rounded-full border-2 border-neutral-50 px-[46px] pt-[14px] pb-[12px] text-sm font-medium uppercase leading-normal text-neutral-50 transition duration-150 ease-in-out hover:border-neutral-100 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-neutral-100 focus:border-neutral-100 focus:text-neutral-100 focus:outline-none focus:ring-0 active:border-neutral-200 active:text-neutral-200 md:mr-2 md:mb-0"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                  href="/academy"
                  role="button">
                  ثبت نام دوره آفلاین
                </a>
                <a
                  className="mb-2 bg-lighter inline-block rounded-full border-2 border-neutral-50 px-[46px] pt-[14px] pb-[12px] text-sm font-medium uppercase leading-normal text-neutral-50 transition duration-150 ease-in-out hover:border-neutral-100 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-neutral-100 focus:border-neutral-100 focus:text-neutral-100 focus:outline-none focus:ring-0 active:border-neutral-200 active:text-neutral-200 md:mr-2 md:mb-0"
                  // className="inline-block rounded-full px-12 pt-4 pb-3.5 text-sm font-medium uppercase leading-normal text-neutral-50 transition duration-150 ease-in-out hover:bg-neutral-500 hover:bg-opacity-20 hover:text-neutral-200 focus:text-neutral-200 focus:outline-none focus:ring-0 active:text-neutral-300"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                  href="http://fitlinez.com"
                  role="button">
                  دانلود اپلیکیشن
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="-mt-2.5 text-white dark:text-neutral-800 md:-mt-4 lg:-mt-6 xl:-mt-10 h-[50px] scale-[2] origin-[top_center]">
        <svg viewBox="0 0 2880 48" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M 0 48 L 1437.5 48 L 2880 48 L 2880 0 L 2160 0 C 1453.324 60.118 726.013 4.51 720 0 L 0 0 L 0 48 Z"
            fill="currentColor"></path>
        </svg>
      </div>
    </section>
  );
}

export default Hero;
