import api from './api';

const getUserProgress = async (userId) => {
  try {
    const res = await api.get(`/quiz/progress/${userId}`);
    console.log('user score progress', res);
    return res.data;
  } catch (error) {
    return {
      totalScore: 0,
      totalPassed: 0,
      totalFailed: 0,
    };
  }
};

export default getUserProgress;
