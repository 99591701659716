import Hero from './hero';
import Services from './Services';
import CookieConsent from 'react-cookie-consent';
import FeatureCompare from './featureCompare';

const Home = () => {
  return (
    <div dir="ltr">
      <div className="bg-transparent flex flex-col">
        <div className="flex w-full flex-col max-md:max-w-full">
          <div className="flex-col self-stretch relative flex  mt-5 pt-1.5 px-5 max-md:max-w-full">
            <Hero />
          </div>
          <Services />
          <FeatureCompare />

          <CookieConsent
            location="bottom"
            buttonText="Accept"
            cookieName="myAwesomeCookieName2"
            style={{ background: '#2B373B' }}
            buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
            expires={150}>
            This website uses cookies to enhance the user experience.{' '}
          </CookieConsent>
        </div>
      </div>
    </div>
  );
};

export default Home;
