import { Button, Card } from 'flowbite-react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import CommentForm from '../../../../component/comment';
import Attachments from '../../../../component/attachments';
import AdditionalLinks from '../../../../component/additionalLinks';
import SessionQuiz from '../../../../component/quiz';
import './style.css';

const ClassSession = () => {
  const { state } = useLocation();

  const {
    video,
    link,
    attachments,
    _id,
    product,
    session,
    description,
    additionalLinks,
  } = state.data;

  const userId = localStorage.getItem('userId');

  console.log('Class Session Data:', state.data);

  return (
    <>
      {/* Back to Dashboard Button */}
      <button
        className="mx-4 font-bold cursor-pointer bg-main rounded p-2 text-white"
        onClick={() => (window.location.href = '/dashboard')}>
        {`  < برگشت به صفحه اصلی`}
      </button>

      {/* Main Content Container */}
      <div dir="ltr" className="max-w-full bg-white">
        {/* Video and Description Section */}
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-2 items-center lg:mx-9">
          {/* Video or External Link */}
          <div className="mt-4">
            {video ? (
              <video
                src={video}
                controls
                className="w-full lg:h-96 object-cover"
              />
            ) : (
              <iframe
                className="w-full lg:h-96"
                src={link}
                allow="autoplay"></iframe>
            )}
          </div>

          {/* Session Description */}
          <div
            dir="rtl"
            className="rounded-lg p-4 h-auto border-gray-300 m-auto">
            <h4 className="text-2xl font-bold">توضیحات جلسه</h4>
            <p className="text-gray-700 p-4 dark:text-gray-400">
              {description}
            </p>
          </div>
        </div>
        <div className="flex flex-col mx-5 mt-5">
          <div
            dir="rtl"
            className="collapse collapse-plus border-base-300 rounded-box mb-5">
            <input type="checkbox" id="main-report" />
            <label
              className="collapse-title text-xl font-medium text-white bg-main"
              htmlFor="main-report">
              پیوست ها
            </label>
            <div className="collapse-content mb-3">
              <div className="mb-8">
                <div className="flex flex-col lg:flex-row justify-between mt-5">
                  <Attachments attachments={attachments} />
                </div>
              </div>
            </div>

            {/* Attachments Accordion */}

            {additionalLinks?.length > 0 && (
              <div className="collapse collapse-plus border border-base-300 bg-base-100 rounded-box">
                <input type="checkbox" id={`attachments-${_id}`} />
                <label
                  className="collapse-title text-xl font-medium bg-main text-white"
                  htmlFor={`attachments-${_id}`}>
                  لینک ها
                </label>
                <div
                  className="collapse-content"
                  onClick={
                    (e) => e.stopPropagation() /* Prevent accordion toggle */
                  }>
                  <AdditionalLinks additionalLinks={additionalLinks} />
                </div>
              </div>
            )}

            {/* Quiz Accordion */}
            {product === 'Shape up Academy' && (
              <div className="collapse collapse-plus border border-base-300 bg-base-100 rounded-box mt-4">
                <input type="checkbox" id={`quiz-${_id}`} />
                <label
                  className="collapse-title text-xl font-medium bg-main text-white"
                  htmlFor={`quiz-${_id}`}>
                  آزمون جلسه
                </label>
                <div
                  className="collapse-content"
                  onClick={
                    (e) => e.stopPropagation() /* Prevent accordion toggle */
                  }>
                  <SessionQuiz sessionId={_id} userId={userId} />
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Comments Section */}
        <div className="flex flex-row">
          <CommentForm id={_id} product={product} session={session} />
        </div>
      </div>
    </>
  );
};

export default ClassSession;
